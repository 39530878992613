<template>
  <div>
    {{ nbUser }}
  </div>
</template>
  
  <script>
  import { mapGetters } from 'vuex';
  export default {
      props: {object: {type: Object, required: true}},
      data(){
          return  {
  
          }
      },
      methods: {
          
          
      },
      computed: {
          ...mapGetters({
            users: 'auth/users'
          }),
          nbUser(){
            return [...this.users].filter(item => item.typeCompte === this.object.uid).length
          }
      }
  }
  </script>
  
  <style>
  
  </style>